import "js-loading-overlay";
export const spinner = document.getElementById("loading-spinner");
export const page = window.location.pathname;
export function formatDate(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateObject = new Date(dateString);
  const day = dateObject.getDate();
  const monthIndex = dateObject.getMonth();
  const year = dateObject.getFullYear();

  return `${months[monthIndex]} ${day}, ${year}`;
}

export function getDomainName(url) {
  // Create a new URL object
  const urlObj = new URL(url);
  
  // Get the hostname (e.g., 'www.tattooed.co')
  let hostname = urlObj.hostname;
  
  // Remove 'www.' if it exists
  if (hostname.startsWith('www.')) {
      hostname = hostname.slice(4);
  }
  
  return hostname;
}

export function formatEventDate(dateString) {
  // Create a Date object from the string
  const date = new Date(dateString);

  // Options for formatting the date
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  // Use Intl.DateTimeFormat to format the date
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
}



export function convertToUrlFriendly(eventName) {
  // Convert to lowercase
  eventName = eventName.toLowerCase();
  
  // Remove special characters and numbers using regex
  eventName = eventName.replace(/[^\w\s]/g, '');

  // Replace spaces with hyphens
  eventName = eventName.replace(/\s+/g, '-');

  return eventName;
}

export function formatNumberWithCommas(number) {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatYear(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateObject = new Date(dateString);
  const day = dateObject.getDate();
  const monthIndex = dateObject.getMonth();
  const year = dateObject.getFullYear();

  return `${year}`;
}

export function capitalizeFirstLetter(str) {
  // Convert the entire string to lowercase first.
  const lowerCasedStr = str.toLowerCase();

  // Then capitalize the first letter of each word.
  return lowerCasedStr.replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
}

export function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\D/g, ""); // Remove all non-digit characters

  if (phoneNumber.length > 0) {
    phoneNumber = phoneNumber.replace(
      /(\d{3})(\d{0,3})(\d{0,4}).*/,
      "($1) $2-$3"
    ); // Apply formatting
  }

  return phoneNumber;
}

export async function logoutAccount() {
  const { auth } = await import("./firebase.js");
  // ^ Make sure "./firebase.js" is the correct path to your Firebase file
  auth.signOut().then(() => {
    console.log('Logged out');
      // Sign-out successful.
      destroySession();
      setTimeout(function () {
        // CREATE PHP SESSION
        setTimeout(function () {
          $("#loading-spinner").hide();
          location = "/";
        }, 1000);
      }, 1000);
    })
    .catch((error) => {
      //console.log("logout error");
    });
}

// export function destroySession() {
//   $.ajax({
//     type: "POST",
//     url: "/utilities/userAccounts.php",
//     data: "action=unsetsession",
//     success: function (msg) {
//       if (msg == "success") {

//       } else {

//       }
//     },
//     error: function (msg) {
//       //alert("Error: cannot load page.");
//     },
//   });
// }

function destroySession() {
  fetch("/utilities/delete_session.php")
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json(); // Parse the JSON response
    })
    .then((data) => {
      
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function showCustomLoadingOverlay() {
  JsLoadingOverlay.show({
    'overlayBackgroundColor': '#FFF',
    'overlayOpacity': 0.9,
    'spinnerIcon': 'ball-atom',
    'spinnerColor': '#555',
    'spinnerSize': '2x',
    'overlayIDName': 'overlay',
    'spinnerIDName': 'spinner',
  });
}

export function hideCustomLoadingOverlay() {
  JsLoadingOverlay.hide();
}

export function showCustomLoadingOverlayMain() {
  JsLoadingOverlay.show({
    'overlayBackgroundColor': '#FFF',
    'overlayOpacity': 0.9,
    'spinnerIcon': 'ball-atom',
    'spinnerColor': '#555',
    'spinnerSize': '2x',
    'overlayIDName': 'overlay',
    'spinnerIDName': 'spinner',
  });
}

export function hideCustomLoadingOverlayMain() {
  JsLoadingOverlay.hide();
}



const countries = {
  "US": "United States",
  "AR": "Argentina",
  "AU": "Australia",
  "AT": "Austria",
  "BE": "Belgium",
  "BR": "Brazil",
  "CA": "Canada",
  "CN": "China",
  "CO": "Colombia",
  "DK": "Denmark",
  "EG": "Egypt",
  "FR": "France",
  "DE": "Germany",
  "GR": "Greece",
  "HU": "Hungary",
  "IE": "Ireland",
  "IT": "Italy",
  "JP": "Japan",
  "MX": "Mexico",
  "MC": "Monaco",
  "NL": "Netherlands",
  "NZ": "New Zealand",
  "NO": "Norway",
  "PS": "Palestine",
  "RU": "Russia",
  "ZA": "South Africa",
  "KR": "South Korea",
  "ES": "Spain",
  "SE": "Sweden",
  "CH": "Switzerland",
  "TH": "Thailand",
  "TR": "Turkey",
  "AE": "United Arab Emirates",
  "UK": "United Kingdom",
  "VE": "Venezuela"
};

export function getCountryName(countryCode) {
  return countries[countryCode] || "";
}